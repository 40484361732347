import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/GSA/banner"
import Capabilities from "../components/GSA/capabilities"
import AboutUs from "../components/aboutUs"
import Slider from "../components/slider"
import ContactUs from "../components/contactUs"

// Context API data
import { AppContext } from "../store/AppContext"

const GSAPage = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    ogTitle,
    ogDescription,
    bannerImage,
    title,
    subtitle,
    description,
    qualitiesTitle,
    qualities,
    gsaContractDuns,
    companyFounded,
    duns,
    companySize,
    cageCode,
    pocEmail,
    pocNumber,
    pocImage,
    pocName,
    pocDesignation,
    capabilitiesTitle,
    capabilities,
  } = data.gsa
  const bannerData = {
    bannerImage,
    title,
    subtitle,
    description,
    qualitiesTitle,
    qualities,
    gsaContractDuns,
    companyFounded,
    duns,
    companySize,
    cageCode,
    pocEmail,
    pocNumber,
    pocImage,
    pocName,
    pocDesignation,
  }
  return (
    <Layout>
      <SEO title={ogTitle || "GSA"} description={ogDescription} />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />
      <Capabilities data={{ capabilities, capabilitiesTitle }} />
      <AboutUs />
      <Slider blueBackground />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query GSAPageQuery {
    gsa: contentfulGsaPage {
      ogTitle
      ogDescription
      bannerImage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      title
      subtitle
      description {
        json
      }
      qualitiesTitle
      qualities {
        id
        title
        description {
          description
        }
      }
      gsaContractDuns
      companyFounded
      duns
      companySize
      cageCode
      pocEmail
      pocNumber
      pocImage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      pocName
      pocDesignation
      capabilitiesTitle
      capabilities {
        id
        title
        description {
          description
        }
        image {
          title
          file {
            url
          }
        }
      }
    }
  }
`

export default GSAPage
