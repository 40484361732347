import React from "react"

const Capabilities = ({ data: { capabilities, capabilitiesTitle } }) => {
  return (
    <div className="max-w-6xl mx-auto px-4 md:px-8 xl:px-0 mb-8">
      <hr className="mt-8 mb-16 md:mx-auto border-b border-blue-400" />
      <h2 className="uppercase text-4xl text-white font-bold siteFont">
        {capabilitiesTitle}
      </h2>
      <div className="mx-auto">
        {capabilities.map(item => (
          <div className="md:flex md:py-8" key={item.id}>
            <div className="w-24 md:mx-4 md:my-0 my-4">
              <img src={item.image.file.url} alt={item.image.title} />
              {/* <Img fluid={item.image.fluid} alt={item.image.title} /> */}
            </div>
            <div className="md:w-3/4">
              <h3 className="text-2xl my-1 text-white font-bold siteFont">
                {item.title}
              </h3>
              <p className="Gray">{item.description.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Capabilities
