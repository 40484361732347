import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import RichTextRenderer from "../RichTextRenderer"

// Background Image component
import Background from "../backgroundImage"

const Banner = ({
  data: {
    bannerImage,
    title,
    subtitle,
    description,
    qualitiesTitle,
    qualities,
    gsaContractDuns,
    companyFounded,
    duns,
    companySize,
    cageCode,
    pocEmail,
    pocNumber,
    pocImage,
    pocName,
    pocDesignation,
  },
  customNav,
}) => {
  // Temp array for toggle having first value "true" default behaviour
  const numOfQuestion = Array.from({ length: qualities.length }).map((_, id) =>
    id === 0 ? true : false
  )
  const [isOpen, setIsOpen] = useState(numOfQuestion)

  const toggle = index => {
    const updateFaq = isOpen.map((_, i) =>
      index === i ? !isOpen[index] : false
    )
    setIsOpen(updateFaq)
  }

  return (
    <Background className={customNav ? "pt-8 md:pt-16" : ""}>
      <div className="max-w-6xl px-4 mx-auto md:px-8 xl:px-0">
        <div className="flex flex-wrap-reverse md:flex-no-wrap">
          <div className="w-full">
            <div className="py-4 font-bold text-white md:pt-12 siteFont">
              <h1 className="text-3xl uppercase md:text-5xl">{title}</h1>
              <h3 className="my-4 text-3xl">{subtitle}</h3>
            </div>

            <Link
              to="/contact"
              className="inline-block w-1/2 p-4 mb-8 text-xl text-center text-white capitalize transition duration-300 ease-in-out rounded-full md:w-2/5 md:block bg-orange focus:outline-none md:mb-8 siteFont hover:bg-darkOrange"
            >
              Contact Us
            </Link>
          </div>
          <div className="w-3/5 mt-12 md:w-11/12">
            <Img
              fluid={bannerImage.fluid}
              alt={bannerImage.title}
              fade={false}
              loading="eager"
            />
          </div>
        </div>
        <div className="md:flex md:my-8">
          <div className="md:w-3/5">
            <div className="my-4 Gray">
              {RichTextRenderer(description.json)}
            </div>
            <div className="my-8 font-bold text-white">
              <h3 className="my-3 text-2xl uppercase siteFont">Company data</h3>
              <div className="md:flex">
                <ul>
                  <li className="pb-3">
                    GSA Contract:{" "}
                    <span className="font-normal Gray">{gsaContractDuns}</span>
                  </li>
                  <li className="pb-3">
                    DUNS: <span className="font-normal Gray">{duns}</span>
                  </li>
                  <li className="pb-3">
                    CAGE Code:{" "}
                    <span className="font-normal Gray">{cageCode}</span>
                  </li>
                </ul>
                <ul className="md:mx-12">
                  <li className="pb-3">
                    Company Founded:{" "}
                    <span className="font-normal Gray">{companyFounded}</span>
                  </li>
                  <li>
                    Company Size:{" "}
                    <span className="font-normal Gray">{companySize}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="my-8 font-bold text-white">
              <h3 className="my-3 text-xl siteFont">Point of Contact:</h3>

              <ul>
                <li className="pb-3">
                  Email: <span className="font-normal Gray">{pocEmail}</span>
                </li>
                <li className="pb-3">
                  Phone: <span className="font-normal Gray">{pocNumber}</span>
                </li>
              </ul>
              <div className="flex">
                <div className="w-16">
                  <Img fluid={pocImage.fluid} alt={pocImage.title} />
                </div>
                <div className="px-4 py-2">
                  <p>{pocName}</p>
                  <span className="text-xs font-normal uppercase">
                    {pocDesignation}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="mx-auto text-white md:w-1/3">
            <h3 className="my-3 text-3xl font-bold uppercase siteFont">
              {qualitiesTitle}
            </h3>
            {qualities.map((quality, index) => (
              <div className="mb-4" key={quality.id}>
                <div
                  className="flex items-start justify-between px-4 py-2 border rounded-md cursor-pointer drop-down"
                  role="button"
                  onKeyDown={() => toggle(index)}
                  onClick={() => toggle(index)}
                >
                  <h4 className="pt-1 font-bold uppercase siteFont">
                    {quality.title}
                  </h4>
                  <span
                    onClick={() => toggle(index)}
                    onKeyDown={() => toggle(index)}
                    className="flex items-center justify-center rounded-full focus:outline-none"
                    role="button"
                  >
                    {isOpen[index] ? "-" : "+"}
                  </span>
                </div>
                <p
                  className={`Gray ${
                    isOpen[index] ? "block" : "hidden"
                  } p-4 pb-0`}
                >
                  {quality.description.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Background>
  )
}

export default Banner
